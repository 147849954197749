import styled from "styled-components";

export const BlockVideoToken = styled.div`
    width: 50%;
    display : flex;
    align-items: center;
    justify-content : flex-start;
    @media screen and (max-width : 1199px) {
        width : 100%;
        justify-content : center;
        margin : 25px 0px 0px 0px;
    }
`
export const VideoBackground = styled.video`
    height: 500px;
    @media screen and (max-width : 1199px) {
        height: 450px;
        max-width: 100%;
    }
`