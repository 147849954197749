import { LogoIcon } from '@pancakeswap/uikit'
import { Social } from 'views/Dashboard/style'
import { BlockInfoFooter, ContentDisclaimer, TitleDisclaimer, WrapDisclaimer, WrapLogo } from './style'
import { ListListLink, LinkItem, ImgLink } from '../InfoToken/style'

const InfoFooter = () => {
  return (
    <BlockInfoFooter>
      <WrapLogo>
        <LogoIcon width={70} />
        <Social>
          <ListListLink className="listLink">
            <LinkItem className="itemLink" href="https://twitter.com/0xArb20" target="_blank" rel="noopener">
              <ImgLink src="/images/icX.svg" />
            </LinkItem>
            <LinkItem className="itemLink" href="" target="_blank" rel="noopener">
              <ImgLink src="/images/icTelegram.svg" />
            </LinkItem>
          </ListListLink>
        </Social>
      </WrapLogo>
      <WrapDisclaimer>
        <TitleDisclaimer>@2023 ARB20. All Rights Reserved.</TitleDisclaimer>
        <ContentDisclaimer>
          Disclaimer: Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down
          as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
        </ContentDisclaimer>
      </WrapDisclaimer>
    </BlockInfoFooter>
  )
}
export default InfoFooter
