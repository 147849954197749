import Link from "next/link";
import styled from "styled-components";

export const BlockInfoToken = styled.div`
    width: calc(50% - 20px);
    @media screen and (max-width : 1199px) {
        width : 100%;
    }
`
export const TitleInfo = styled.div`
    font-size : 3.6rem;
    line-height: calc(3.6rem * 1.25);
    font-family : SUISSE_BLACK;
    @media screen and (max-width : 1199px) {
       text-align : center;
       font-size : 2.2rem;
       line-height: calc(2.2rem * 1.25);
    }
`
export const DescInfo = styled.div`
    font-family : SUISSE_MEDIUM;
    margin : 20px 0px 0px 0px;
    @media screen and (max-width : 1199px) {
        text-align : center;
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
        margin : 10px 0px 0px 0px;
    }
`

export const ButtonLink = styled(Link)`
    width: fit-content;
    button {
        width : fit-content;
        padding : 0px 25px;
        height: 50px;
        font-family : SUISSE_BOLD;
        color : #FFF;
        @media screen and (max-width : 1199px) {
            margin : 0px auto 0px auto;
            height: 40px;
            font-size : 1.25rem;
            line-height: calc(1.25rem * 1.25);
            border-radius : 5px;
        }
    }
`

export const WrapDirect = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
    margin : 25px 0px 0px 0px;
    button {
        margin : 0px 10px 0px 0px;
        img {
            margin : 0px 10px 0px 0px;
        }
    }
    @media screen and (max-width : 1199px) {
        margin : 15px 0px 0px 0px;
        justify-content : center;
        button {
            margin : 0px 5px;
           
        }
    }
`
export const ListListLink = styled.div`
    display : flex;
    margin : 0px 0px 0px 20px;
    @media screen and (max-width : 1199px) {
        justify-content : center;
        margin : 15px 0px 0px 0px;
    }
`
export const LinkItem = styled(Link)`
    margin : 0px 5px;
    width: fit-content;
    background : #000;
    border : 1px solid #FFF;
    padding: 0px 10px;
    height: 40px;
    border-radius : 8px;
    transition : 0.4s all;
    cursor : pointer;
    display : flex;
    align-items : center;
    &:hover {
        filter : brightness(0.7);
        transition : 0.4s all;
    }
`
export const ImgLink = styled.img`
    width : 16px;
    height: 16px;
    filter : invert(1);
    @media screen and (max-width : 1199px) {
        width : 16px;
        height: 16px;
    }
    @media screen and (max-width : 380px) {
        width : 12px;
        height: 12px;
    }
`

export const TextLink = styled.div`
    color : #FFF;
    margin : 0px 0px 0px 10px;
    font-family : SUISSE_BOLD;
    @media screen and (max-width : 1199px) {
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
    }
    @media screen and (max-width : 380px) {
        font-size : 1rem;
        line-height: calc(1rem * 1.25);
    }
`


export const WrapCountDownMint = styled.div`
    background : linear-gradient(91deg, #e7f7fe 0%, #e7f7fe 57.81%, #fbebff 81.77%, #f0fced 100%);
    max-width:  400px;
    margin : 25px auto 0px auto;
    padding: 15px;
    border-radius : 14px;
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    position : relative;
    overflow : hidden;
`

export const TextDays = styled.div`
  margin: 0px 10px 0px 0px;
  font-family : SUISSE_BLACK;
  text-transform : uppercase;
`;
export const InfoListing = styled.div``;
export const InfoTime = styled.div`
    background : #000;
    width: fit-content;
    min-width: 170px;
    display : flex;
    justify-content : center;
    align-items : flex-end;
    padding: 10px 20px;
    border-radius : 10px 0px 0px 10px;
    margin : 10px 0px 0px 0px;
  
`;
export const TextHours = styled.div`
font-family : SUISSE_BLACK;
`;
export const TextMinutes = styled.div`
font-family : SUISSE_BLACK;
    `;
export const TextSeconds = styled.div`
font-family : SUISSE_BLACK;
`;

export const TitleListing = styled.div`
    color : #000;
    font-size:  1.25rem;
    line-height: calc(1.25rem * 1.25);
    text-align: center;
    font-family : SUISSE_BOLD;
`
export const MoneyListing = styled.div`
    color : #1e7b9e;
    font-family : SUISSE_BLACK;
    font-size:  2rem;
    line-height: calc(2rem * 1.25);
    text-align: center;
    margin : 5px 0px 0px 0px;
`
export const WrapMint = styled.div`
    display : flex;
    align-items : center;
    margin : 15px 0px 0px 0px;
`

export const InfoButton = styled.div`
    margin : 10px 0px 0px 2.5px;
    button {
        height: 37.5px;
        border-radius : 0px 10px 10px 0px;
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
        font-family : SUISSE_BLACK;
    }
`

export const ImgBackground1 = styled.img`
    position : absolute;
    top : 10px;
    width: 60px;
    object-fit : contain;
    right: -25px;
`
export const ImgBackground2 = styled.img`
    position : absolute;
    top : 25px;
    left: -20px;
    width: 60px;
    object-fit : contain;
`