import { Button } from '@pancakeswap/uikit/src/components/Button'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import {
  BlockInfoToken,
  ButtonLink,
  DescInfo,
  ImgBackground1,
  ImgBackground2,
  ImgLink,
  InfoButton,
  InfoListing,
  InfoTime,
  LinkItem,
  ListListLink,
  MoneyListing,
  TextDays,
  TextHours,
  TextLink,
  TextMinutes,
  TextSeconds,
  TitleInfo,
  TitleListing,
  WrapCountDownMint,
  WrapDirect,
  WrapMint,
} from './style'

const InfoToken = () => {
  const calculateTimeRemaining = () => {
    const now = new Date()
    const targetDate = new Date('2024-05-15T00:00:00')
    const timeRemaining = +targetDate - +now

    const days = Math.floor(timeRemaining / (24 * 60 * 60 * 1000))
    const hours = Math.floor((timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
    const minutes = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000))
    const seconds = Math.floor((timeRemaining % (60 * 1000)) / 1000)

    return { days, hours, minutes, seconds }
  }

  const [countdown, setCountdown] = useState(calculateTimeRemaining())

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(calculateTimeRemaining())
    }, 1000)

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer)
  }, [])
  return (
    <BlockInfoToken>
      <TitleInfo>ARB20 - A Star is Born on Arbitrum </TitleInfo>
      <DescInfo>
        Forget ERC-20, there&apos;s a new sheriff in town. Enter ARB20, the revolutionary token standard poised to take
        the Arbitrum network by storm. Built on the familiar foundation of its predecessor, ARB20 cranks things up a
        notch with enhanced security, scalability, and a feature set that&apos;ll leave you breathless.
      </DescInfo>
      <DescInfo>
        But ARB20 is more than just a speed demon. It&apos;s a playground for developers, letting them craft
        applications brimming with possibilities. Think staking, governance, and even NFTs – all seamlessly integrated
        into the ARB20 experience.
      </DescInfo>
      <DescInfo>
        This is a token standard with ambition. It doesn&apos;t just want to handle your digital pennies; it wants to
        empower you to build the future. So, buckle up, developers and users alike, because the ARB20 revolution is
        here, and it&apos;s unstoppable. Dive in, explore, and let your imagination run wild on the Arbitrum network.
        The future is bright, and it&apos;s painted in the colors of ARB20.
      </DescInfo>
      <WrapDirect>
        {/* <ButtonLink href="/mint-token">
          <Button>Mint</Button>
        </ButtonLink> */}
        <ButtonLink
          href="https://arbiscan.io/address/0x501a1b0ccb7fd67cb20fa55aae513dbbba1742f7"
          target="_blank"
          rel="noopener"
        >
          <Button>
            <ImgLink src="/images/icContract.svg" /> Contract
          </Button>
        </ButtonLink>
        <ButtonLink href="https://arb-20.gitbook.io/whitepaper/" target="_blank" rel="noopener">
          <Button>
            <ImgLink src="/images/icDocs.svg" /> Docs
          </Button>
        </ButtonLink>
      </WrapDirect>
      <WrapCountDownMint>
        <InfoListing>
          <TitleListing>CEX Listing</TitleListing>
          <MoneyListing>$0.1</MoneyListing>
        </InfoListing>
        <WrapMint>
          <InfoTime>
            <TextDays>{countdown.days} days</TextDays>
            <TextHours>{String(countdown.hours).padStart(2, '0')}:</TextHours>
            <TextMinutes>{String(countdown.minutes).padStart(2, '0')}:</TextMinutes>
            <TextSeconds>{String(countdown.seconds).padStart(2, '0')}</TextSeconds>
          </InfoTime>
          <InfoButton>
            <Link href="/mint-token">
              <Button>Mint Token</Button>
            </Link>
          </InfoButton>
          <ImgBackground1 src="/images/1.webp" />
          <ImgBackground2 src="/images/2.webp" />
        </WrapMint>
      </WrapCountDownMint>
    </BlockInfoToken>
  )
}
export default InfoToken
