import { BlockVideoToken, VideoBackground } from './style'

const VideoToken = () => {
  return (
    <BlockVideoToken>
      <VideoBackground src="/videoToken.mp4" autoPlay loop muted playsInline />
    </BlockVideoToken>
  )
}
export default VideoToken
