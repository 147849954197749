import styled from "styled-components"

export const BlockInfoFooter = styled.div`
    display : flex;
    background : rgba(16,16,16);
    flex-direction : column;
    align-items : center;
    padding: 30px 15px;
`
export const WrapLogo = styled.div`
    display : flex;
    flex-direction : column;
    align-items:  center;
`
export const WrapDisclaimer = styled.div`
    max-width : 800px;
    margin : 30px 0px 0px 0px;
`
export const TitleDisclaimer = styled.div`
    text-align : center;
`
export const ContentDisclaimer = styled.div`
    text-align : center;
    margin : 10px 0px 0px 0px;
    font-size : 1.25rem;
    line-height: calc(1.25rem * 1.25);
    color : rgba(180,180,180);
`