import dynamic from 'next/dynamic'
import InfoFooter from './components/InfoFooter'
import InfoToken from './components/InfoToken'

import VideoToken from './components/VideoToken'

import { BlockDashboard, BlockDashboardWrap, WrapInfo } from './style'
// import VideoTrailer from './components/VideoTrailer'

const ListCharacter = dynamic(() => import('./components/ListCharacter'), {
  ssr: false,
})

const Dashboard = () => {
  return (
    <>
      <BlockDashboard>
        <BlockDashboardWrap className="blockContainer">
          <WrapInfo>
            <VideoToken />
            <InfoToken />
          </WrapInfo>
          {/* <VideoTrailer /> */}
        </BlockDashboardWrap>
        <ListCharacter />
        <InfoFooter />
      </BlockDashboard>
    </>
  )
}

export default Dashboard
